import { ActionType, NavBar, useNavBar } from '@mom/components'
import Box from '@mui/material/Box'
import MuiContainer, { ContainerProps } from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import { Actions, Subjects } from 'lib/ability'
import { useAuth } from 'lib/auth/useAuth'
import { titleCase } from 'lib/strings'
import { useUserPermissions } from 'lib/useUserPermissions'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const Container = styled(MuiContainer, { shouldForwardProp: (prop) => prop !== 'fullWidth' })<
  ContainerProps & { fullWidth: boolean; open: boolean }
>((props) => ({
  marginTop: '2rem',
  marginLeft: `${props.fullWidth ? 0 : props.open ? 14 : 3}rem`,
  width: 'auto',
  transition: props.theme.transitions.create('all', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
  }),
}))

export const App = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { can } = useUserPermissions()
  const [selectedAction, setSelectedAction] = useState<ActionType>()
  const navBar = useNavBar()

  const onAction = (action: ActionType) => {
    switch (action) {
      case 'Home':
        navigate('/')
        break

      case 'Logout':
        auth.logout()
        navigate('/login')
        break

      default:
        navigate(`/${action.toLowerCase()}`)
        break
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setSelectedAction('Home')
        break

      default:
        setSelectedAction(titleCase(location.pathname.split('/')[1]) as ActionType)
        break
    }
  }, [location])

  return (
    <Container fullWidth={!auth.isLoggedIn} open={navBar.isOpen} maxWidth={false}>
      {auth.isLoggedIn && (
        <NavBar
          canSeeMerchants={can(Actions.LIST, Subjects.MERCHANT)}
          canSeeCorporations={can(Actions.LIST, Subjects.CORPORATIONS)}
          canSeeDBANames={can(Actions.LIST, Subjects.DBANAMES)}
          selectedAction={selectedAction}
          onAction={onAction}
        />
      )}

      <Box component='main' width='100%' height='100vh'>
        <Outlet />
      </Box>
    </Container>
  )
}

export default App
