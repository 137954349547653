import { Page, UserTasksDataGrid } from '@mom/components'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useAuth } from 'lib/auth/useAuth'
import { useIntl } from 'react-intl'

import { DashboardNotificationList } from './DashboardNotificationList'

const DashboardSection = styled('section')(() => ({
  marginBottom: '2rem',
}))

export const Dashboard = () => {
  const intl = useIntl()
  const { user } = useAuth()

  return (
    <Page title={intl.formatMessage({ id: 'pages.dashboard.title' })}>
      <DashboardSection id='notifications'>
        <Typography variant='h4'>Notifications</Typography>
        <DashboardNotificationList />
      </DashboardSection>
      <DashboardSection id='tasks'>
        <Typography variant='h4'>Tasks</Typography>
        {user && (
          <Box width={1000} height={300}>
            <UserTasksDataGrid user={user} />
          </Box>
        )}
      </DashboardSection>
    </Page>
  )
}
