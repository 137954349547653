import { createContextualCan } from '@casl/react'
import { AppAbility, defineAbilityFor } from 'lib/ability'
import { useAuth } from 'lib/auth/useAuth'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

export const AbilityContext = createContext({} as AppAbility)
export const Can = createContextualCan(AbilityContext.Consumer)

export const UserPermissionsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const [currentAbilities, setCurrentAbilities] = useState(defineAbilityFor(user))

  useEffect(() => {
    setCurrentAbilities(defineAbilityFor(user))
  }, [user])

  return <AbilityContext.Provider value={currentAbilities}>{children}</AbilityContext.Provider>
}

export const useUserPermissions = () => {
  return useContext(AbilityContext)
}
