import { Page } from '@mom/components'
import { useIntl } from 'react-intl'

export const Help = () => {
  const intl = useIntl()

  return (
    <Page title={intl.formatMessage({ id: 'pages.help.title' })}>
      <div />
    </Page>
  )
}
