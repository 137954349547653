import { AddressInput, Button } from '@mom/components'
import { Address, Merchant, addLocationToMerchant, createLocationFromAddress } from '@mom/services'
import Grid from '@mui/material/Grid'
import { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

interface AddSingleLocationFormProps {
  merchant: Merchant
}

export const AddSingleLocationForm = ({ merchant }: AddSingleLocationFormProps) => {
  const form = useForm<Address>({
    defaultValues: {
      StreetAddress: '',
      PropertyAddress: '',
      City: '',
      County: '',
      Region: '',
      StateCode: '',
      CountryCode: '',
      PostalCode: '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, formState, setValue } = form
  const { dirtyFields, errors, isValid } = formState

  const onSubmit: SubmitHandler<Address> = async (data) => {
    const location = await createLocationFromAddress(data)
    if (location) {
      await addLocationToMerchant(merchant.Id, location)
    }
  }

  const onCountriesLoaded = useCallback(() => {
    if (merchant.Address?.CountryCode) {
      setValue('CountryCode', merchant.Address?.CountryCode)
    }
  }, [merchant.Address?.CountryCode])

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <AddressInput required onCountriesLoaded={onCountriesLoaded} />

          <Grid display='flex' justifyContent='flex-end' item xs={12}>
            <Button type='submit' disabled={!isValid}>
              <FormattedMessage id='buttons.save' />
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
