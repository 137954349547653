import { ControlledCheckbox, ControlledTextField, IndustryCodeAutocomplete } from '@mom/components'
import { IndustryCode } from '@mom/services'
import { Control, Controller, FieldErrorsImpl, FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormItem } from './FormItem'
import { MerchantOnboardingFormBasicsValues } from './MerchantOnboardingFormBasicsFields'

interface MerchantOnboardingFormPropertiesFieldsProps {
  control: Control<MerchantOnboardingFormBasicsValues>
  draft: ConstrainBoolean
  errors?: FieldErrorsImpl
  dirtyFields: Record<string, boolean>
}

export type MerchantOnboardingFormPropertiesValues = FieldValues & {
  crmId: string
  sicCode: Nullable<IndustryCode>
  naicsCode: Nullable<IndustryCode>
  includeInDbaRollup: boolean
}

export const MerchantOnboardingFormPropertiesFields = ({
  control,
  draft,
  dirtyFields,
  errors,
}: MerchantOnboardingFormPropertiesFieldsProps) => {
  const intl = useIntl()

  return (
    <>
      <FormItem>
        <ControlledTextField name='crmId' required={!draft} control={control} />
      </FormItem>

      <FormItem>
        <Controller
          name='sicCode'
          control={control}
          rules={{
            required: {
              value: !draft,
              message: intl.formatMessage(
                { id: 'forms.validation.required' },
                { field: intl.formatMessage({ id: 'forms.fields.sicCode' }) },
              ),
            },
          }}
          render={({ field }) => (
            <IndustryCodeAutocomplete
              type='SIC'
              required={!draft}
              errorText={errors?.['sicCode']?.message?.toString()}
              isDirty={!!dirtyFields['sicCode']}
              label={intl.formatMessage({ id: 'forms.fields.sicCode' })}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem>
        <Controller
          name='naicsCode'
          control={control}
          rules={{
            required: {
              value: !draft,
              message: intl.formatMessage(
                { id: 'forms.validation.required' },
                { field: intl.formatMessage({ id: 'forms.fields.naicsCode' }) },
              ),
            },
          }}
          render={({ field }) => (
            <IndustryCodeAutocomplete
              type='NAICS'
              required={!draft}
              errorText={errors?.['naicsCode']?.message?.toString()}
              isDirty={!!dirtyFields['naicsCode']}
              label={intl.formatMessage({ id: 'forms.fields.naicsCode' })}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem>
        <ControlledCheckbox control={control} name='includeInDbaRollup' />
      </FormItem>
    </>
  )
}
