import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant='h3' gutterBottom>
        <FormattedMessage id='pages.404.title' />
      </Typography>
      <div>
        <FormattedMessage
          id='pages.404.homepageMessage'
          values={{ Link: (chunks) => <Link to='/'>{chunks}</Link> }}
        />
      </div>
    </Box>
  )
}
