import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { User, convertSsoToken, fetchUserProfile, login, useSessionStorage } from '@mom/services'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

export interface AuthContext {
  clientId: string
  isLoggedIn: boolean
  loginWithPassword: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  user?: User
  token?: string
}

const authContext = createContext<AuthContext>({
  clientId: '',
  isLoggedIn: false,
  loginWithPassword: () => Promise.reject('Uninitialized'),
  logout: () => Promise.reject('Uninitialized'),
})

export const AuthProvider = ({ clientId, children }: { clientId: string; children: ReactNode }) => {
  const [token, setToken] = useSessionStorage('mom-api-token', '')
  const [, setRefreshToken] = useSessionStorage('mom-api-refresh-token', '')
  const [isLoggedIn, setLoggedIn] = useState(!!token)
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()
  const [user, setUser] = useState<User>()

  const loginWithPassword = async (email: string, password: string) => {
    const response = await login({ email, password })
    setLoggedIn(true)
    setToken(response.Result.BearerToken)
    setRefreshToken(response.Result.RefreshToken)
  }

  useEffect(() => {
    if (token) {
      setLoggedIn(true)
    }
  }, [token])

  useEffect(() => {
    const loginWithSso = async () => {
      try {
        const tokenResponse = await instance.acquireTokenSilent({
          account: instance.getAllAccounts()[0],
          scopes: [`api://${clientId}/access_as_user`],
        })
        const response = await convertSsoToken(tokenResponse.accessToken)
        setLoggedIn(true)
        setToken(response.Result.BearerToken)
        setRefreshToken(response.Result.RefreshToken)
      } catch (e) {
        setLoggedIn(false)
      }
    }

    if (isAuthenticated) {
      setLoggedIn(true)
      loginWithSso()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserProfile().then((user: User) => setUser(user))
    }
  }, [isLoggedIn])

  const logout = async () => {
    setToken('')
    setUser(undefined)
    setLoggedIn(false)

    return Promise.resolve()
  }

  return (
    <authContext.Provider
      value={{
        clientId,
        isLoggedIn,
        loginWithPassword,
        logout,
        token,
        user,
      }}
    >
      {isLoggedIn && !user ? <CircularProgress /> : children}
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext)
}
