import { AddressInput } from '@mom/components'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Control, FormProvider, UseFormReturn } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { FormItem } from './FormItem'
import { MerchantOnboardingFormAssetsFields } from './MerchantOnboardingFormAssetsFields'
import {
  MerchantOnboardingFormBasicsFields,
  MerchantOnboardingFormBasicsValues,
} from './MerchantOnboardingFormBasicsFields'
import { MerchantOnboardingFormContactFields } from './MerchantOnboardingFormContactFields'
import { MerchantOnboardingFormDescriptionFields } from './MerchantOnboardingFormDescriptionFields'
import { MerchantOnboardingFormPropertiesFields } from './MerchantOnboardingFormPropertiesFields'
import { MerchantOnboardingFormSettingsFields } from './MerchantOnboardingFormSettingsFields'
import {
  MerchantOnboardingAssets,
  MerchantOnboardingContact,
  MerchantOnboardingSettings,
} from './merchantOnboardingMachine'

type FormData = MerchantOnboardingFormBasicsValues &
  MerchantOnboardingAssets &
  MerchantOnboardingContact &
  MerchantOnboardingSettings

export interface MerchantOnboardingFormProps {
  draft: boolean
  form: UseFormReturn<FormData, unknown>
  onSubmit: (data: FormData) => void
}

export const MerchantOnboardingForm = ({ draft, form, onSubmit }: MerchantOnboardingFormProps) => {
  const { control, handleSubmit, formState } = form
  const { dirtyFields, errors } = formState

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.businessName' />
            </Typography>
            <MerchantOnboardingFormBasicsFields
              draft={draft}
              control={control as unknown as Control<MerchantOnboardingFormBasicsValues>}
              dirtyFields={dirtyFields}
              errors={errors}
            />
          </section>

          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.properties' />
            </Typography>
            <MerchantOnboardingFormPropertiesFields
              draft={draft}
              control={control as unknown as Control<MerchantOnboardingFormBasicsValues>}
              dirtyFields={dirtyFields}
              errors={errors}
            />
          </section>

          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.address' />
            </Typography>
            <FormItem>
              <AddressInput required={false} />
            </FormItem>
          </section>

          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.contactDetails' />
            </Typography>
            <MerchantOnboardingFormContactFields
              draft={draft}
              control={control as unknown as Control<MerchantOnboardingContact>}
            />
          </section>

          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.assets' />
            </Typography>
            <MerchantOnboardingFormAssetsFields
              draft={draft}
              control={control as unknown as Control<MerchantOnboardingAssets>}
            />
          </section>

          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.description' />
            </Typography>
            <MerchantOnboardingFormDescriptionFields
              draft={draft}
              control={control as unknown as Control<MerchantOnboardingAssets>}
            />
          </section>

          <section>
            <Typography fontWeight='bold' variant='h4'>
              <FormattedMessage id='forms.sections.settings' />
            </Typography>
            <MerchantOnboardingFormSettingsFields
              draft={draft}
              control={control as unknown as Control<MerchantOnboardingSettings>}
            />
          </section>
        </Stack>
      </form>
    </FormProvider>
  )
}
