import { CentredLayout, Page } from '@mom/components'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { MerchantEditSidebar } from 'components/Merchant/MerchantEditSidebar'
import { MerchantTabs } from 'components/Merchant/MerchantTabs'
import { MerchantOnboardingForm } from 'components/MerchantOnboarding/MerchantOnboardingForm'
import { MerchantOnboardingFormBasicsValues } from 'components/MerchantOnboarding/MerchantOnboardingFormBasicsFields'
import {
  MerchantOnboardingAssets,
  MerchantOnboardingContact,
  MerchantOnboardingSettings,
} from 'components/MerchantOnboarding/merchantOnboardingMachine'

type FormData = MerchantOnboardingFormBasicsValues &
  MerchantOnboardingAssets &
  MerchantOnboardingContact &
  MerchantOnboardingSettings

const defaultValues = {
  corporation: null,
  dba: null,
  crmId: '',
  naicsCode: null,
  sicCode: null,
  includeInDbaRollup: false,
  contactPerson: '',
  StreetAddress: '',
  PropertyAddress: '',
  City: '',
  County: '',
  Region: '',
  StateCode: '',
  CountryCode: '',
  PostalCode: '',
  email: '',
  telephone: '',
  website: '',
  defaultLanguage: '',
  defaultCurrency: '',
  highlights: '',
  logoImages: [],
  galleryImages: [],
}

const readLocalStorage = () => {
  const localData = localStorage.getItem('merchant.all')
  return localData ? JSON.parse(localData) : defaultValues
}

export const AddNewMerchant = () => {
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const restore = Boolean(searchParams.get('resume'))
  const [saveStatus, setSaveStatus] = useState('D')
  const form = useForm<FormData>({
    defaultValues: restore ? readLocalStorage() : defaultValues,
    mode: 'onChange',
  })

  const { formState, handleSubmit, watch, setValue, trigger } = form

  const { clear } = useFormPersist('merchant.all', {
    watch,
    setValue,
    storage: window.localStorage,
  })

  if (!restore) {
    clear()
  }

  const { dirtyFields, errors, isValid } = formState

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (data: FormData) => {
      await trigger()
      if (isValid) {
        console.debug(data)
      }
    },
    [form],
  )

  const naicsCode = watch('naicsCode')
  const sicCode = watch('sicCode')

  useEffect(() => {
    if (naicsCode?.SicCode && naicsCode?.SicCode !== sicCode?.SicCode) {
      setValue('sicCode', naicsCode)
    }
  }, [naicsCode])

  useEffect(() => {
    if (sicCode?.Code && sicCode?.Code !== naicsCode?.Code) {
      setValue('naicsCode', sicCode)
    }
  }, [sicCode])

  return (
    <Page
      renderToolbar={() => <MerchantTabs selectedIndex={0} />}
      renderSidebar={() => (
        <MerchantEditSidebar
          isNewMerchant
          status={saveStatus}
          onChangeStatus={(status) => setSaveStatus(status)}
          onSave={() => handleSubmit(onSubmit)()}
          tasks={[]}
        />
      )}
      title={intl.formatMessage({ id: 'pages.merchantAdd.title' })}
    >
      <CentredLayout>
        <MerchantOnboardingForm draft={saveStatus === 'D'} form={form} onSubmit={onSubmit} />
      </CentredLayout>
    </Page>
  )
}
