import { CentredLayout, Page, TextField } from '@mom/components'
import { MerchantNameDetailWithStatus } from '@mom/services'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useLocationState } from 'lib/useLocationState'
import { Link } from 'react-router-dom'

import { MerchantNameSidebar } from 'components/MerchantName/MerchantNameSidebar'

const MerchantListItem = styled(ListItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #bdbdbd',
}))

export const ViewCorporation = () => {
  const [corporation] = useLocationState<MerchantNameDetailWithStatus>()

  return (
    <Page
      title={corporation?.Name ?? ''}
      renderSidebar={() => <MerchantNameSidebar status={corporation?.Status ?? ''} tasks={[]} />}
    >
      <CentredLayout>
        <Stack gap={2}>
          <section id='business-name'>
            <Typography variant='h3'>Business name</Typography>
            <TextField label='Corporation name' required value={corporation?.Name} />
          </section>

          <section id='associated-merchants'>
            <Typography variant='h3'>Associated merchants</Typography>
            <List>
              {corporation?.associatedMerchants?.map((merchant) => (
                <MerchantListItem key={merchant.Id}>
                  <span>{merchant.Name}</span>
                  <Link to={`/merchant/${merchant.Id}`}>View record</Link>
                </MerchantListItem>
              ))}
            </List>
          </section>
        </Stack>
      </CentredLayout>
    </Page>
  )
}
