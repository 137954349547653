import { ControlledImageUpload } from '@mom/components'
import { Control } from 'react-hook-form'

import { FormItem } from './FormItem'
import { MerchantOnboardingAssets } from './merchantOnboardingMachine'

interface MerchantOnboardingFormAssetsFieldsProps {
  control: Control<MerchantOnboardingAssets>
  draft: boolean
}

export const MerchantOnboardingFormAssetsFields = ({
  control,
  draft,
}: MerchantOnboardingFormAssetsFieldsProps) => {
  return (
    <>
      <FormItem>
        <ControlledImageUpload control={control} name='logoImages' required={!draft} />
      </FormItem>

      <FormItem>
        <ControlledImageUpload control={control} name='galleryImages' />
      </FormItem>
    </>
  )
}
