import { faLocationDot, faStore, faTruck } from '@fortawesome/pro-solid-svg-icons'
import { CentredLayout, LinkCard, MerchantLocationsDataGrid, Page } from '@mom/components'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useMerchantState } from 'lib/useMerchantState'
import { Link, useParams } from 'react-router-dom'

import { MerchantTabs } from 'components/Merchant/MerchantTabs'

export const ViewMerchantLocations = () => {
  const { merchant } = useMerchantState()
  const { merchantId } = useParams()

  return (
    <Page
      renderToolbar={() => <MerchantTabs merchant={merchant} selectedIndex={1} />}
      title={merchant?.DoingBusinessAs?.Name ?? ''}
    >
      <CentredLayout fluid>
        <Stack gap={2}>
          {merchant && (
            <MerchantLocationsDataGrid merchantId={merchant.Id}>
              <Typography>
                No locations yet for this merchant, please make a selection below.
              </Typography>
            </MerchantLocationsDataGrid>
          )}

          <Grid container display='flex' direction='row'>
            <Grid item px={1} xs={4}>
              <LinkCard icon={faStore} text='Use merchant address' />
            </Grid>
            <Grid item px={1} xs={4}>
              <Link
                to={{
                  pathname: `/merchant/${merchantId}/locations/add`,
                }}
                state={{
                  data: merchant,
                }}
              >
                <LinkCard icon={faLocationDot} text='New store location' />
              </Link>
            </Grid>
            <Grid item px={1} xs={4}>
              <LinkCard icon={faTruck} text='Mobile radial zone' />
            </Grid>
          </Grid>
        </Stack>
      </CentredLayout>
    </Page>
  )
}
