import { Button, CentredLayout, ControlledTextField, DataGrid, Page } from '@mom/components'
import { MerchantNameDetailWithStatus, fetchCorporationNames } from '@mom/services'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface FormData {
  corporation: string
  createdFromDate: string
  createdToDate: string
}

export const CorporationsSearch = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<MerchantNameDetailWithStatus[]>([])
  const [error, setError] = useState<Error>()
  const navigate = useNavigate()

  const intl = useIntl()
  const form = useForm<FormData>({
    defaultValues: {
      corporation: '',
      createdFromDate: '',
      createdToDate: '',
    },
    mode: 'onChange',
  })

  const { control, handleSubmit, formState } = form
  const { dirtyFields, errors, isValid } = formState

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      setLoading(true)
      const results = await fetchCorporationNames(formData.corporation)
      setData(results)
    } catch (e) {
      setError(e as Error)
    } finally {
      setLoading(false)
    }
  }

  const columns: GridColDef<MerchantNameDetailWithStatus>[] = [
    {
      field: 'Name',
      headerName: intl.formatMessage({ id: 'dataGrids.corporation.corporation' }),
      renderCell: (params) => (
        <a
          href={`/corporations/${params.row.Id}`}
          onClick={(e) => {
            e.preventDefault()
            navigate(`/corporations/${params.row.Id}`, { state: { data: params.row } })
          }}
        >
          {params.row.Name}
        </a>
      ),
      flex: 2,
    },
    {
      field: 'CreatedAt',
      headerName: intl.formatMessage({ id: 'dataGrids.corporation.createdAt' }),
      flex: 2,
    },
    {
      field: 'UpdatedAt',
      headerName: intl.formatMessage({ id: 'dataGrids.corporation.updatedAt' }),
      flex: 1,
    },
  ]

  return (
    <Page title={intl.formatMessage({ id: 'pages.corporationsSearch.title' })}>
      <CentredLayout>
        <Typography variant='h4'>Find corporations</Typography>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField required name='corporation' control={control} />
              </Grid>
              <Grid item xs={5}>
                <ControlledTextField
                  helperText='MM-DD-YYYY'
                  name='createdFromDate'
                  placeholder='From date'
                  control={control}
                />
              </Grid>
              <Grid item xs={2} alignItems='center' display='flex' justifyContent='center'>
                to
              </Grid>
              <Grid item xs={5}>
                <ControlledTextField
                  helperText='MM-DD-YYYY'
                  name='createdToDate'
                  placeholder='To date'
                  control={control}
                />
              </Grid>
              <Grid display='flex' justifyContent='flex-end' item xs={12}>
                <Button type='submit' disabled={!isValid}>
                  <FormattedMessage id='buttons.search' />
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Grid item xs={3} mt={2} ml={2}>
          {error && <Alert severity='error'>{error.message}</Alert>}
        </Grid>
      </CentredLayout>

      <DataGrid
        height={400}
        loading={loading}
        getRowId={(name: MerchantNameDetailWithStatus) => name.Id ?? -1}
        rowHeight={40}
        rows={data ?? []}
        columns={columns}
      />
    </Page>
  )
}
