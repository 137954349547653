import { CorporationNameAutocomplete, DBANameAutocomplete } from '@mom/components'
import { MerchantNameDetail } from '@mom/services'
import { Control, Controller, FieldErrorsImpl, FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormItem } from './FormItem'

interface MerchantOnboardingFormBasicsFieldsProps {
  control: Control<MerchantOnboardingFormBasicsValues>
  draft: boolean
  errors?: FieldErrorsImpl
  dirtyFields: Record<string, boolean>
}

export type MerchantOnboardingFormBasicsValues = FieldValues & {
  corporation: Nullable<MerchantNameDetail>
  dba: Nullable<MerchantNameDetail>
  status: string
}

export const MerchantOnboardingFormBasicsFields = ({
  control,
  draft,
  dirtyFields,
  errors,
}: MerchantOnboardingFormBasicsFieldsProps) => {
  const intl = useIntl()

  return (
    <>
      <FormItem>
        <Controller
          name='corporation'
          control={control}
          rules={{
            required: {
              value: !draft,
              message: intl.formatMessage(
                { id: 'forms.validation.required' },
                { field: intl.formatMessage({ id: 'forms.fields.corporation' }) },
              ),
            },
          }}
          render={({ field }) => (
            <CorporationNameAutocomplete
              required={!draft}
              errorText={errors?.['corporation']?.message?.toString()}
              isDirty={!!dirtyFields['corporation']}
              canAddNewCorporation
              label={intl.formatMessage({ id: 'forms.fields.corporation' })}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem>
        <Controller
          name='dba'
          control={control}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage(
                { id: 'forms.validation.required' },
                { field: intl.formatMessage({ id: 'forms.fields.dba' }) },
              ),
            },
          }}
          render={({ field }) => (
            <DBANameAutocomplete
              errorText={errors?.['dba']?.message?.toString()}
              isDirty={!!dirtyFields['dba']}
              canAddNewDBAName
              label={intl.formatMessage({ id: 'forms.fields.dba' })}
              {...field}
            />
          )}
        />
      </FormItem>
    </>
  )
}
