import { Merchant } from '@mom/services'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface MerchantTabsProps {
  merchant?: Merchant
  selectedIndex: number
}

export const MerchantTabs = ({ merchant, selectedIndex }: MerchantTabsProps) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const enabled = Boolean(merchant)

  return (
    <Tabs textColor='secondary' indicatorColor='secondary' value={selectedIndex}>
      <Tab
        disabled={!enabled}
        onClick={() =>
          enabled && navigate(`/merchant/${merchant?.Id}`, { state: { data: merchant } })
        }
        label={intl.formatMessage({ id: 'appbar.merchant.details' })}
      />
      <Tab
        disabled={!enabled}
        onClick={() =>
          enabled && navigate(`/merchant/${merchant?.Id}/locations`, { state: { data: merchant } })
        }
        label={intl.formatMessage({ id: 'appbar.merchant.locations' })}
      />
      <Tab
        disabled={!enabled}
        onClick={() =>
          enabled && navigate(`/merchant/${merchant?.Id}/offers`, { state: { data: merchant } })
        }
        label={intl.formatMessage({ id: 'appbar.merchant.offers' })}
      />
    </Tabs>
  )
}
