import { Actions, Subjects } from 'lib/ability'
import { useAuth } from 'lib/auth/useAuth'
import { useUserPermissions } from 'lib/useUserPermissions'
import {
  AddNewLocation,
  AddNewMerchant,
  AddNewOffer,
  CorporationsSearch,
  DBASearch,
  Dashboard,
  Help,
  Login,
  MerchantOffersList,
  MerchantsList,
  NotFound,
  Search,
  SelectDiscountType,
  Settings,
  Unauthorized,
  ViewCorporation,
  ViewDBAName,
  ViewMerchant,
  ViewMerchantLocations,
} from 'pages/index'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import App from './App'

export const Router = () => {
  const auth = useAuth()
  const { cannot } = useUserPermissions()

  const AuthGuard = (Component: () => JSX.Element, action?: Actions, subject?: Subjects) => {
    if (auth.isLoggedIn) {
      if (action && subject) {
        if (cannot(action, subject)) {
          return <Navigate to='/unauthorized' />
        }
      }

      return <Component />
    }

    return <Navigate to='/login' />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={AuthGuard(Dashboard)} />
          <Route path='login' element={<Login />} />
          <Route path='search' element={AuthGuard(Search)} />
          <Route
            path='corporations'
            element={AuthGuard(CorporationsSearch, Actions.LIST, Subjects.CORPORATIONS)}
          />
          <Route
            path='corporations/:corporationId'
            element={AuthGuard(ViewCorporation, Actions.EDIT, Subjects.CORPORATIONS)}
          />
          <Route path='dbanames' element={AuthGuard(DBASearch, Actions.LIST, Subjects.DBANAMES)} />
          <Route
            path='dbanames/:dbaNameId'
            element={AuthGuard(ViewDBAName, Actions.EDIT, Subjects.DBANAMES)}
          />
          <Route
            path='merchants'
            element={AuthGuard(MerchantsList, Actions.LIST, Subjects.MERCHANT)}
          />
          <Route
            path='merchant/add'
            element={AuthGuard(AddNewMerchant, Actions.ADD, Subjects.MERCHANT)}
          />
          <Route path='merchant/:merchantId' element={AuthGuard(ViewMerchant)} />
          <Route
            path='merchant/:merchantId/locations/add'
            element={AuthGuard(AddNewLocation, Actions.ADD, Subjects.LOCATION)}
          />
          <Route
            path='merchant/:merchantId/locations'
            element={AuthGuard(ViewMerchantLocations, Actions.LIST, Subjects.LOCATION)}
          />
          <Route
            path='merchant/:merchantId/offers/add'
            element={AuthGuard(AddNewOffer, Actions.ADD, Subjects.OFFER)}
          />
          <Route
            path='merchant/:merchantId/offers/select-discount-type'
            element={AuthGuard(SelectDiscountType, Actions.ADD, Subjects.OFFER)}
          />
          <Route
            path='merchant/:merchantId/offers'
            element={AuthGuard(MerchantOffersList, Actions.LIST, Subjects.LOCATION)}
          />
          <Route path='help' element={<Help />} />
          <Route path='settings' element={AuthGuard(Settings)} />
          <Route path='unauthorized' element={<Unauthorized />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
