import { Merchant } from '@mom/services'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export const MerchantSummaryPanel = ({ merchant }: { merchant: Merchant }) => (
  <Stack spacing={2}>
    <label id='corporationNameLabel'>Corporation name</label>
    <Typography aria-labelledby='corporationNameLabel' fontWeight={200}>
      {merchant.CorporateName.Name}
    </Typography>
    <label id='dbaNameLabel'>DBA name</label>
    <Typography aria-labelledby='dbaNameLabel' fontWeight={200}>
      {merchant.DoingBusinessAs?.Name}
    </Typography>
    <label id='merchantIdLabel'>Merchant ID</label>
    <Typography aria-labelledby='merchantIdLabel' fontWeight={200}>
      {merchant.Id}
    </Typography>

    <Typography variant='h5'>Contact Details</Typography>
    <label id='contactNameLabel'>Contact Name</label>
    <Typography aria-labelledby='contactNameLabel' fontWeight={200} />
    <label id='emailAddressLabel'>Email</label>
    <Typography aria-labelledby='emailAddressLabel' fontWeight={200}>
      {merchant.Email}
    </Typography>
    <label id='telephoneLabel'>Phone</label>
    <Typography aria-labelledby='telephoneLabel' fontWeight={200}>
      {merchant.TelephoneNumber}
    </Typography>
    <label id='websiteURLLabel'>Website URL</label>
    <Typography aria-labelledby='websiteURLLabel' fontWeight={200} />
  </Stack>
)
