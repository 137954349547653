import {
  ControlledCheckbox,
  ControlledImageUpload,
  ControlledRadioButtonsGroup,
  ControlledTextField,
  ControlledTreeView,
  RenderTree,
} from '@mom/components'
import { ImageFile } from '@mom/services'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

export interface OfferFormData {
  discountType: string
  requirePromoCode?: boolean
  maxDiscountValue: number
  offerName: string
  categoryType: string[]
  boProductName: string
  goProductName: string
  includePostRedemptionInstructions: boolean
  postRedemptionInstructions: string
  includePreRedemptionInstructions: boolean
  preRedemptionInstructions: string
  promoCode: string
  promoCodeDescription: string
  promoCodeType: string
  promoCodeUsage: string
  sicCode: string
  naicsCode: string
  offerGalleryImages: ImageFile[]
}

export interface OfferFormProps {
  draft: boolean
  form: UseFormReturn<OfferFormData, unknown>
  onSubmit: (data: OfferFormData) => void
}

const categories: RenderTree = {
  id: 'root',
  name: 'Root',
  children: [
    {
      id: 'leaf-1',
      name: 'Leaf 1',
    },
    {
      id: 'leaf-2',
      name: 'Leaf 2',
    },
  ],
}

export const OfferForm = ({ draft, form, onSubmit }: OfferFormProps) => {
  const intl = useIntl()
  const { control, handleSubmit, formState, watch } = form
  const { dirtyFields, errors } = formState

  const requirePromoCode = watch('requirePromoCode')
  const includePostRedemptionInstructions = watch('includePostRedemptionInstructions')
  const includePreRedemptionInstructions = watch('includePreRedemptionInstructions')

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section id='offer-information'>
          <Typography fontWeight='bold' variant='h4'>
            <FormattedMessage id='forms.sections.offerInformation' />
          </Typography>
          <Stack spacing={2} gap={2}>
            <ControlledTextField required control={control} name='offerName' />
            <ControlledTextField type='number' control={control} name='maxDiscountValue' />
            <ControlledTextField required={!draft} control={control} name='boProductName' />
            <ControlledTextField required={!draft} control={control} name='goProductName' />
            <ControlledTextField required={!draft} control={control} name='sicCode' />
            <ControlledTextField required={!draft} control={control} name='naicsCode' />
            <ControlledTreeView
              items={categories}
              required={!draft}
              control={control}
              name='categoryType'
            />
          </Stack>
        </section>

        <section id='promo-code'>
          <Typography fontWeight='bold' variant='h4'>
            <FormattedMessage id='forms.sections.promoCode' />
          </Typography>
          <Stack spacing={2} gap={2}>
            <ControlledCheckbox control={control} name='requirePromoCode' />
            {requirePromoCode && (
              <>
                <ControlledRadioButtonsGroup
                  control={control}
                  name='promoCodeType'
                  options={[
                    {
                      label: intl.formatMessage({ id: 'forms.options.promoCodeType.text' }),
                      value: 'text',
                    },
                    {
                      label: intl.formatMessage({ id: 'forms.options.promoCodeType.barcode' }),
                      value: 'barcode',
                    },
                    {
                      label: intl.formatMessage({ id: 'forms.options.promoCodeType.qr' }),
                      value: 'qr',
                    },
                    {
                      label: intl.formatMessage({ id: 'forms.options.promoCodeType.unique' }),
                      value: 'unique',
                    },
                  ]}
                />
                <ControlledTextField required={!draft} control={control} name='promoCode' />
                <ControlledTextField
                  required={!draft}
                  control={control}
                  name='promoCodeDescription'
                />
              </>
            )}
          </Stack>
        </section>

        <section id='usage'>
          <Typography fontWeight='bold' variant='h4'>
            <FormattedMessage id='forms.sections.promoCodeUsage' />
          </Typography>
          <Stack spacing={2} gap={2}>
            <ControlledRadioButtonsGroup
              control={control}
              name='promoCodeUsage'
              options={[
                {
                  label: intl.formatMessage({ id: 'forms.options.promoCodeUsage.annual-1' }),
                  value: 'annual-1',
                },
                {
                  label: intl.formatMessage({ id: 'forms.options.promoCodeUsage.annual-3' }),
                  value: 'annual-3',
                },
                {
                  label: intl.formatMessage({ id: 'forms.options.promoCodeUsage.monthly-1' }),
                  value: 'monthly-1',
                },
                {
                  label: intl.formatMessage({ id: 'forms.options.promoCodeUsage.unlimited' }),
                  value: 'unlimited',
                },
              ]}
            />
          </Stack>
        </section>

        <section id='redemption-instructions'>
          <Typography fontWeight='bold' variant='h4'>
            <FormattedMessage id='forms.sections.redemptionInstructions' />
          </Typography>
          <Typography>
            Does the customer require any instructions pre or post-redemption to understand the
            offer requirements?
          </Typography>
          <Stack spacing={2} gap={2}>
            <ControlledCheckbox control={control} name='includePreRedemptionInstructions' />
            {includePreRedemptionInstructions && (
              <ControlledTextField
                multiline
                rows={5}
                required={!draft}
                control={control}
                name='preRedemptionInstructions'
              />
            )}
            <ControlledCheckbox control={control} name='includePostRedemptionInstructions' />
            {includePostRedemptionInstructions && (
              <ControlledTextField
                multiline
                rows={5}
                required={!draft}
                control={control}
                name='postRedemptionInstructions'
              />
            )}
          </Stack>
        </section>

        <section id='offer-assets'>
          <Typography fontWeight='bold' variant='h4'>
            <FormattedMessage id='forms.sections.offerAssets' />
          </Typography>
          <ControlledImageUpload control={control} name='offerGalleryImages' />
        </section>
      </form>
    </FormProvider>
  )
}
