import { ControlledTextField } from '@mom/components'
import InputAdornment from '@mui/material/InputAdornment'
import { Control } from 'react-hook-form'

import { FormItem } from './FormItem'
import { MerchantOnboardingContact } from './merchantOnboardingMachine'

interface MerchantOnboardingFormContactFieldsProps {
  draft: boolean
  control: Control<MerchantOnboardingContact>
}

export const MerchantOnboardingFormContactFields = ({
  draft,
  control,
}: MerchantOnboardingFormContactFieldsProps) => (
  <>
    <FormItem>
      <ControlledTextField name='contactPerson' required={!draft} control={control} />
    </FormItem>

    <FormItem>
      <ControlledTextField
        name='email'
        pattern={/^\S+@\S+$/i}
        required={!draft}
        control={control}
      />
    </FormItem>

    <FormItem>
      <ControlledTextField name='telephone' required={!draft} control={control} />
    </FormItem>

    <FormItem>
      <ControlledTextField
        name='website'
        required={!draft}
        pattern={
          /^(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
        }
        InputProps={{
          startAdornment: <InputAdornment position='start'>https://</InputAdornment>,
        }}
        control={control}
      />
    </FormItem>
  </>
)
