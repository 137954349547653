import { ControlledTextField } from '@mom/components'
import { Control } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormItem } from './FormItem'
import { MerchantOnboardingAssets } from './merchantOnboardingMachine'

interface MerchantOnboardingFormDescriptionFieldsProps {
  control: Control<MerchantOnboardingAssets>
  draft: boolean
}

export const MerchantOnboardingFormDescriptionFields = ({
  control,
  draft,
}: MerchantOnboardingFormDescriptionFieldsProps) => {
  const intl = useIntl()

  return (
    <>
      <FormItem>
        <ControlledTextField
          helperText={intl.formatMessage({ id: 'forms.helperText.highlights' })}
          multiline
          rows={5}
          name='highlights'
          required={!draft}
          control={control}
        />
      </FormItem>
    </>
  )
}
