import {
  DataGrid,
  MerchantLocationsDataGrid,
  MerchantOffersDataGrid,
  Page,
  StatusChip,
  TaskAvatarList,
  formatAddress,
} from '@mom/components'
import { Address } from '@mom/services'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { GridColDef } from '@mui/x-data-grid'
import { useMerchantState } from 'lib/useMerchantState'
import { useIntl } from 'react-intl'

import { MerchantSummaryPanel } from 'components/Merchant/MerchantSummaryPanel'
import { MerchantTabs } from 'components/Merchant/MerchantTabs'

export const ViewMerchant = () => {
  const intl = useIntl()
  const { merchant } = useMerchantState()

  const addressColumns: GridColDef<Address>[] = [
    {
      field: 'Address',
      headerName: intl.formatMessage({ id: 'dataGrids.address.address' }),
      valueGetter: ({ row }) => formatAddress(row),
      flex: 2,
    },
    {
      field: 'Status',
      headerName: intl.formatMessage({ id: 'dataGrids.address.status' }),
      renderCell: (params) => <StatusChip status={params.value} />,
      flex: 2,
    },
    {
      field: 'Tasks',
      headerName: intl.formatMessage({ id: 'dataGrids.location.tasks' }),
      renderCell: (params) => (params.value ? <TaskAvatarList tasks={params.value} /> : null),
      flex: 2,
    },
  ]

  return (
    <Page
      renderToolbar={() => <MerchantTabs merchant={merchant} selectedIndex={0} />}
      title={merchant?.DoingBusinessAs?.Name ?? ''}
    >
      <Grid container>
        <Grid item xl={2} />
        <Grid item xs={2}>
          {merchant && <MerchantSummaryPanel merchant={merchant} />}
        </Grid>
        <Grid item xs={8} xl={6}>
          {merchant && merchant.Address && (
            <Stack gap={2}>
              <Typography>Merchant Details</Typography>
              <DataGrid
                getRowId={(address: Address) => address.PostalCode ?? address.StreetAddress}
                rows={[merchant.Address]}
                columns={addressColumns}
              />
              <Typography>Locations</Typography>
              <MerchantLocationsDataGrid merchantId={merchant.Id} />
              <Typography>Offers</Typography>
              <MerchantOffersDataGrid merchantId={merchant.Id} />
            </Stack>
          )}
        </Grid>
        <Grid item xl={2} />
      </Grid>
    </Page>
  )
}
