import { useMsal } from '@azure/msal-react'
import { Button, CentredLayout, ControlledTextField } from '@mom/components'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useAuth } from 'lib/auth/useAuth'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface LoginFormFields {
  email: string
  password: string
}

export const LoginForm = () => {
  const form = useForm<LoginFormFields>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })
  const [error, setError] = useState<string | undefined>()
  const auth = useAuth()
  const navigate = useNavigate()
  const { instance } = useMsal()
  const { control, handleSubmit, formState } = form
  const { isValid, dirtyFields, errors } = formState

  const onSubmit = async (data: LoginFormFields) => {
    try {
      await auth.loginWithPassword(data.email, data.password)
      navigate('/')
    } catch (e: unknown) {
      setError(e?.toString())
    }
  }

  const loginWithSSO = () => {
    instance
      .loginRedirect({
        scopes: [`api://${auth.clientId}/access_as_user`],
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const clearError = () => setError(undefined)

  return (
    <CentredLayout>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Typography variant='h2'>Log in</Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <ControlledTextField
                name='email'
                autoFocus
                control={control}
                required
                pattern={/^\S+@\S+$/i}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ControlledTextField name='password' type='password' required control={control} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <Button fullWidth disabled={!isValid} type='submit'>
                <FormattedMessage id='buttons.login' />
              </Button>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Button color='secondary' variant='text' disableElevation onClick={loginWithSSO}>
                <FormattedMessage id='buttons.ssoLogin' />
              </Button>
            </Grid>
            <Grid item xs={12} lg={8}>
              {error && (
                <Alert onClose={clearError} severity='error'>
                  {error}
                </Alert>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </CentredLayout>
  )
}
