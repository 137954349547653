import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, MerchantOffersDataGrid, Page } from '@mom/components'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Actions, Subjects } from 'lib/ability'
import { Can } from 'lib/useUserPermissions'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

export const MerchantOffersList = () => {
  const { merchantId } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()

  const addNewOffer = () => navigate(`/merchant/${merchantId}/offers/select-discount-type`)

  return (
    <Page title={intl.formatMessage({ id: 'pages.merchantsList.title' })}>
      <Grid width='100%' container marginBottom='1.25rem'>
        <Grid item lg={3} xs={6}>
          <Typography variant='h4'>Offers</Typography>
        </Grid>
        <Grid item lg={3} />
        <Grid item xs={6} display='flex' justifyContent='flex-end'>
          <Can I={Actions.ADD} a={Subjects.OFFER}>
            {() => (
              <Button onClick={addNewOffer} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                <FormattedMessage id='buttons.addNewOffer' />
              </Button>
            )}
          </Can>
        </Grid>
      </Grid>

      <Grid item md={8} xs={12}>
        {merchantId && <MerchantOffersDataGrid merchantId={Number.parseInt(merchantId)} />}
      </Grid>
    </Page>
  )
}
