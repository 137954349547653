import { Page } from '@mom/components'
import { useIntl } from 'react-intl'

export const Search = () => {
  const intl = useIntl()

  return (
    <Page title={intl.formatMessage({ id: 'pages.search.title' })}>
      <div />
    </Page>
  )
}
