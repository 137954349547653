import { ControlledSelect } from '@mom/components'
import { Control } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormItem } from './FormItem'
import { MerchantOnboardingSettings } from './merchantOnboardingMachine'

interface MerchantOnboardingFormSettingsFieldsProps {
  control: Control<MerchantOnboardingSettings>
  draft: boolean
}

export const MerchantOnboardingFormSettingsFields = ({
  control,
  draft,
}: MerchantOnboardingFormSettingsFieldsProps) => {
  const intl = useIntl()

  return (
    <>
      <FormItem>
        <ControlledSelect
          control={control}
          name='defaultLanguage'
          required={!draft}
          options={[
            {
              label: intl.formatMessage({ id: 'forms.options.languages.en-US' }),
              value: 'en-US',
            },
            {
              label: intl.formatMessage({ id: 'forms.options.languages.en-GB' }),
              value: 'en-GB',
            },
            {
              label: intl.formatMessage({ id: 'forms.options.languages.fr-CA' }),
              value: 'fr-CA',
            },
          ]}
        />
      </FormItem>

      <FormItem>
        <ControlledSelect
          control={control}
          name='defaultCurrency'
          required={!draft}
          options={[
            { label: intl.formatMessage({ id: 'forms.options.currencies.USD' }), value: 'USD' },
            { label: intl.formatMessage({ id: 'forms.options.currencies.GBP' }), value: 'GBP' },
            { label: intl.formatMessage({ id: 'forms.options.currencies.CAD' }), value: 'CAD' },
          ]}
        />
      </FormItem>
    </>
  )
}
