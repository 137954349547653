import { Button, NewTaskDialog, StatusChip, StatusSelect, TaskAvatarList } from '@mom/components'
import type { Task } from '@mom/services'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

interface OfferEditSidebarProps {
  isNewOffer: boolean
  status: string
  onChangeStatus: (status: string) => void
  onSave: () => void
  tasks: Task[]
}

export const OfferEditSidebar = ({
  isNewOffer,
  status,
  onChangeStatus,
  onSave,
  tasks,
}: OfferEditSidebarProps) => {
  const [newTaskDialogVisible, setNewTaskDialogVisible] = useState(false)

  const showNewTaskDialog = () => setNewTaskDialogVisible(true)
  const hideNewTaskDialog = () => setNewTaskDialogVisible(false)

  return (
    <Stack padding={2} gap={2}>
      {isNewOffer ? (
        <>
          <InputLabel>Status</InputLabel>
          <StatusChip status={status} />
        </>
      ) : (
        <>
          <StatusSelect isAdminUser value={status} onStatusChange={onChangeStatus} />
        </>
      )}
      <Button onClick={() => onSave()}>Save</Button>
      <InputLabel>Tasks</InputLabel>
      <TaskAvatarList includeAddNewTask onAddNewTask={showNewTaskDialog} tasks={tasks} />
      <NewTaskDialog
        taskType='update-offer'
        open={newTaskDialogVisible}
        onClose={hideNewTaskDialog}
      />
    </Stack>
  )
}
