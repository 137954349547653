import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useLocationState = <T extends object>() => {
  let initialValue: Maybe<T> = undefined
  const { state } = useLocation()
  if (state) {
    initialValue = (state as { data?: T }).data
  }

  return useState(initialValue)
}
