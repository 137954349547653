import { NewTaskDialog, StatusChip, TaskAvatarList } from '@mom/components'
import type { Task } from '@mom/services'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

interface MerchantNameSidebarProps {
  status: string
  tasks: Task[]
}

export const MerchantNameSidebar = ({ status, tasks }: MerchantNameSidebarProps) => {
  const [newTaskDialogVisible, setNewTaskDialogVisible] = useState(false)

  const showNewTaskDialog = () => setNewTaskDialogVisible(true)
  const hideNewTaskDialog = () => setNewTaskDialogVisible(false)

  return (
    <Stack padding={2} gap={2}>
      <>
        <InputLabel>Status</InputLabel>
        <StatusChip status={status} />
      </>

      <InputLabel>Tasks</InputLabel>
      <TaskAvatarList includeAddNewTask onAddNewTask={showNewTaskDialog} tasks={tasks} />
      <NewTaskDialog
        taskType='update-merchant'
        open={newTaskDialogVisible}
        onClose={hideNewTaskDialog}
      />
    </Stack>
  )
}
