import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { NavBarProvider, theme } from '@mom/components'
import { messages } from '@mom/services'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { msalConfig } from 'config/msalConfig'
import { AuthProvider } from 'lib/auth/useAuth'
import { UserPermissionsProvider } from 'lib/useUserPermissions'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Router } from './Router'
import reportWebVitals from './reportWebVitals'

const msalInstance = new PublicClientApplication(msalConfig)
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <IntlProvider messages={messages.en} locale='en-US' defaultLocale='en-US'>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider clientId={msalConfig.auth.clientId}>
            <NavBarProvider>
              <UserPermissionsProvider>
                <QueryClientProvider client={queryClient}>
                  <Router />
                </QueryClientProvider>
              </UserPermissionsProvider>
            </NavBarProvider>
          </AuthProvider>
        </ThemeProvider>
      </MsalProvider>
    </IntlProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
